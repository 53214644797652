<template>

	<!-- Profile Information Card -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingRight: 0,}">
		<hr class="my-25">
		<a-descriptions title="个人资料" :column="1">
			<a-descriptions-item label="姓名">
				{{ user.userName }}
			</a-descriptions-item>
			<a-descriptions-item label="手机号">
        {{ user.phonenumber }}
			</a-descriptions-item>
      <a-descriptions-item label="证件号">
        {{ user.idNumber }}
      </a-descriptions-item>
			<a-descriptions-item label="邮箱">
				{{ user.email }}
			</a-descriptions-item>
			<a-descriptions-item label="地址">
				{{ user.address }}
			</a-descriptions-item>
      <a-descriptions-item label="通讯地址">
        {{ user.mailAddress }}
      </a-descriptions-item>
      <a-descriptions-item label="职业">
        {{ user.job }}
      </a-descriptions-item>
		</a-descriptions>
	</a-card>
	<!-- / Profile Information Card -->

</template>

<script>

	import user from "../../store/modules/user";

  export default ({
    props: {
      user: {
        type: Object
      }
    },
		data() {
			return {
			}
		},
	})

</script>